/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type FC, useRef, useState } from "react";
import useCustomDropzone from "./DropBox";
import { toast } from "react-hot-toast";
import { API_URL, GATSBY_SIGNATURE } from "@/npc-api/config";
import axios from "axios";
import { navigate } from "gatsby";

interface FormValues {
  name: string;
  midName: string;
  lastName: string;
  email: string;
  phone: string;
  linkedin: string;
  texperience: string;
  rexpir: string;
  reason: string;
}

const FormD: FC<{ id: string }> = ({ id }) => {
  const { DropzoneBox, files, errors } = useCustomDropzone({
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 10,
  });
  const [isLoading, setIsLoading] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const midNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const linkedinRef = useRef<HTMLInputElement>(null);
  const texperienceRef = useRef<HTMLInputElement>(null);
  const rexpirRef = useRef<HTMLInputElement>(null);
  const reasonRef = useRef<HTMLTextAreaElement>(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  function validateLinkedInUrl(linkedinUrl: string): boolean {
    // Expresión regular para validar una URL de LinkedIn
    const linkedinRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company|groups|edu)\/[a-zA-Z0-9-]+(\/.*)?$/;

    // Comprobar si la cadena de texto coincide con la expresión regular
    return linkedinRegex.test(linkedinUrl);
  }
  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();

      const values: FormValues = {
        name: nameRef.current?.value ?? "",
        midName: midNameRef.current?.value ?? "",
        lastName: lastNameRef.current?.value ?? "",
        email: emailRef.current?.value ?? "",
        phone: phoneRef.current?.value ?? "",
        linkedin: linkedinRef.current?.value ?? "",
        texperience: texperienceRef.current?.value ?? "",
        rexpir: rexpirRef.current?.value ?? "",
        reason: reasonRef.current?.value ?? "",
      };

      if (errors.length > 0 || files.length === 0) {
        toast.error("Please, select a valid file");
        return;
      }
      if (!values.name.trim()) {
        nameRef.current?.focus();
        toast.error("Invalid Name");
        return;
      }
      if (!values.lastName.trim()) {
        lastNameRef.current?.focus();
        toast.error("Invalid Last Name");
        return;
      }
      if (!values.midName.trim()) {
        midNameRef.current?.focus();
        toast.error("Invalid Middle Name");
        return;
      }
      if (!emailRegex.test(values.email)) {
        emailRef.current?.focus();
        toast.error("Invalid email");
        return;
      }
      if (!values.phone.trim()) {
        phoneRef.current?.focus();
        toast.error("Invalid Phone");
        return;
      }
      if (!validateLinkedInUrl(values.linkedin)) {
        linkedinRef.current?.focus();
        toast.error(
          "Invalid Url, example: https://www.linkedin.com/in/username",
        );
        return;
      }
      if (!values.texperience.trim()) {
        texperienceRef.current?.focus();
        toast.error("Invalid Total Experience");
        return;
      }
      if (!values.rexpir.trim()) {
        rexpirRef.current?.focus();
        toast.error("Invalid Relevant Experience");
        return;
      }
      if (!values.reason.trim()) {
        reasonRef.current?.focus();
        toast.error("Invalid Reason of Leaving Current/Previous Organisation");
        return;
      }

      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("midName", values.midName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("linkedInUrl", values.linkedin);
      formData.append("experience", values.texperience);
      formData.append("relevantExperience", values.rexpir);
      formData.append("leavingReason", values.reason);
      formData.append("jobRoleId", id && id ? id : "");
      formData.append("resume", files[0]);
      formData.append("signature", GATSBY_SIGNATURE);

      await axios.post(`${API_URL}/job-applies/apply`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      toast.success("We recieved your request!! Our team will reach you.");

      navigate("/careers");
    } catch (error) {
      setIsLoading(false);
      toast.error("Error submiting");
    }
  };

  return (
    <div className="ftfr">
      <h3>SUBMIT YOUR APPLICATION</h3>
      <span>Send us your CV and join us on board!</span>
      <div style={{ width: "100%" }}>{DropzoneBox}</div>
      {errors.length > 0 && (
        <p style={{ color: "red" }} className="error m--0 p-0">
          {errors[0]}
        </p>
      )}
      {files.length > 0 && (
        <p style={{ color: "#f9b233" }} className="m--0 p-0 title">
          {files[0].name}
        </p>
      )}
      <form
        className="mt--20"
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
      >
        <div className="form-group">
          <label htmlFor="First Name">First Name</label>
          <input
            ref={nameRef}
            className="form-control input-form-cv"
            id="First Name"
            autoComplete="off"
            type="text"
            name="name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="MiddleName">Middle Name</label>
          <input
            ref={midNameRef}
            className="form-control input-form-cv"
            id="MiddleName"
            autoComplete="off"
            type="text"
            name="midName"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Last Name">Last Name</label>
          <input
            ref={lastNameRef}
            className="form-control col input-form-cv"
            id="Last Name"
            autoComplete="off"
            type="text"
            name="lastName"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Email">Email</label>
          <input
            ref={emailRef}
            className="form-control input-form-cv"
            id="Email"
            autoComplete="off"
            type="email"
            name="email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Phone Number">Phone Number</label>
          <input
            ref={phoneRef}
            className="form-control input-form-cv"
            id="Phone Number"
            autoComplete="off"
            type="text"
            name="phone"
            required
          />
        </div>
        <h5 className="linkedin-title mt-30">LINKEDIN &amp; EXPERIENCE</h5>
        <div className="form-group mb--0 mt--20">
          <label htmlFor="Linkedin URL">Linkedin URL</label>
          <input
            ref={linkedinRef}
            className="form-control input-form-cv"
            id="Linkedin URL"
            autoComplete="off"
            type="text"
            name="linkedInUrl"
            required
          />
        </div>

        <div className="row mt--20">
          <div className="col">
            <label htmlFor="rs">Total Experience (in years)</label>
            <input
              ref={texperienceRef}
              id="rs"
              type="number"
              className="form-control input-form-cv"
              placeholder="Total Experience (in years)"
              required
            />
          </div>
          <div className="col">
            <label htmlFor="tre">Relevant Experience (in years)</label>
            <input
              ref={rexpirRef}
              id="tre"
              type="number"
              className="form-control input-form-cv"
              placeholder="Relevant Experience (in years)"
              required
            />
          </div>
        </div>
        <div className="form-group mt--20">
          <label htmlFor="lrs">
            Reason of Leaving Current/Previous Organisation
          </label>
          <textarea
            ref={reasonRef}
            className="form-control input-form-cv"
            autoComplete="off"
            name="leavingReason"
            required
          ></textarea>
        </div>
        <button
          disabled={isLoading}
          type="submit"
          className="btn-submit-cv mt--20"
        >
          {isLoading ? "Loading..." : "Apply"}
        </button>
      </form>
    </div>
  );
};

export default FormD;
