import React, { useState, useCallback } from "react";
import Dropzone, {
  type DropzoneProps,
  type DropzoneState,
} from "react-dropzone";

interface CustomHookOptions extends DropzoneProps {}

interface CustomHookReturnType {
  files: DropzoneState["acceptedFiles"];
  DropzoneBox: JSX.Element;
  errors: string[];
}

function useCustomDropzone(options: CustomHookOptions): CustomHookReturnType {
  const [files, setFiles] = useState([] as DropzoneState["acceptedFiles"]);

  const [errors, setErrors] = useState([] as string[]);

  const handleDrop = useCallback(
    (
      acceptedFiles: DropzoneState["acceptedFiles"],
      rejectedFiles: DropzoneState["fileRejections"],
    ) => {
      if (rejectedFiles.length > 0) {
        setErrors([
          "Invalid file format or size. Please select just a single file in PDF or DOC format, and no larger than 10MB.",
        ]);
        setFiles([]);
      } else {
        setFiles(acceptedFiles);
        setErrors([]);
      }
    },
    [setFiles, setErrors],
  );

  const DropzoneBox = (
    <Dropzone onDrop={handleDrop} {...options}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={`${
            errors.length > 0 ? "cv-area-error cv-area" : "cv-area"
          }`}
          style={{ width: "100%" }}
        >
          <input {...getInputProps()} />
          <img src="/images/careers/upload-ico.svg" alt="" />
          <div className="infc">
            <p>Attach your Resume</p>
            <span>PDF, DOC (Max. 10MB)</span>
          </div>
        </div>
      )}
    </Dropzone>
  );

  return { files, errors, DropzoneBox };
}

export default useCustomDropzone;
