/* eslint-disable @typescript-eslint/no-floating-promises */
import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import axios from "axios";
import { API_URL } from "@/npc-api/config";
import { type singleJob } from "@/components/Careers/Jobs";
import BannerD2 from "@/components/Careers/BannerD2";
import FormD from "@/components/Careers/Details/FormD";

const JobDetails = ({ params }: any) => {
  const [jobRole, setJobRole] = useState<singleJob | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = params;

  const fetchJobRole = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${API_URL}/job-roles/${String(id)}`);
      setJobRole(res.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      navigate("/careers");
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!id) {
      navigate("/careers");
    }
    fetchJobRole();
  }, []);
  return (
    <Layout>
      <SEO title="Job Details" canonical={`${useLocation().host}`} />
      <Breadcrumb
        imgUrl="/images/careers/v2.jpeg"
        name="Job Details"
        pathName="Job Details"
      />
      <main className="container mt--40 mb--80">
        <div className="top-wrapper-det">
          <h3>{jobRole != null ? jobRole.name : ""}</h3>
          <span
            onClick={() => {
              navigate("/careers");
            }}
          >
            Back to Careers
          </span>
        </div>
        {isLoading && <h1>Loading...</h1>}
        {jobRole !== null && !isLoading && (
          <div className="job-details-wrapper">
            <div>
              <h5>Key Duties / Responsibilities / Accountabilities</h5>
              <ul>
                {jobRole != null ? (
                  jobRole.keyDuties.keyDuties.map((e, i) => (
                    <li key={`${i}`}>{e}</li>
                  ))
                ) : (
                  <></>
                )}
              </ul>
              <h5 className="mt--30">
                Duties, Required Knowledge, Skills and Abilities:
              </h5>
              <ul>
                {jobRole != null ? (
                  jobRole.duties.duties.map((e, i) => <li key={`${i}`}>{e}</li>)
                ) : (
                  <></>
                )}
              </ul>
              <h5 className="mt--30">Qualifications</h5>
              <h6 className="mt--10">Minimum Qualifications</h6>
              <ul>
                {jobRole != null ? (
                  jobRole.minQualifications.minQualifications.map((e, i) => (
                    <li key={`${i}`}>{e}</li>
                  ))
                ) : (
                  <></>
                )}
              </ul>
              <h6 className="mt--30">Preferred qualifications</h6>
              <ul>
                {jobRole != null ? (
                  jobRole.preferedQualifications.preferedQualifications.map(
                    (e, i) => <li key={`${i}`}>{e}</li>,
                  )
                ) : (
                  <></>
                )}
              </ul>
              <h5 className="mt--30">
                Environment and Cognitive / Physical Demands
              </h5>
              <h6 className="mt--10">Environment And Cognitive</h6>
              <ul>
                {jobRole != null ? (
                  jobRole.enviroment.enviroment.map((e, i) => (
                    <li key={`${i}`}>{e}</li>
                  ))
                ) : (
                  <></>
                )}
              </ul>
              <h6 className="mt--30">Physical demands</h6>
              <ul>
                {jobRole != null ? (
                  jobRole.physicalDemands.physicalDemands.map((e, i) => (
                    <li key={`${i}`}>{e}</li>
                  ))
                ) : (
                  <></>
                )}
              </ul>
            </div>
            <div className="form-wrapper-cd">
              <FormD id={jobRole != null ? String(jobRole.id) : ""} />
            </div>
          </div>
        )}
      </main>
      <BannerD2 />
    </Layout>
  );
};

export default JobDetails;
